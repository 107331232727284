<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-dark text-light">
          <h3 class="panel-title bold">List Pelanggan</h3>
        </div>
        <div class="panel-body p-5">
          <div class="row" v-if="userLevel == 'admin'">
            <div class="col-5">
              <select
                v-model="filter.district"
                v-on:change="fetchVillages($event)"
                class="form-control mb-2"
              >
                <option value="">Pilih Kecamatan</option>
                <option
                  v-for="districts in districtList"
                  :value="districts.id_kec"
                  :key="districts.id_kec"
                >
                  {{ districts.nama_kecamatan }}
                </option>
              </select>
            </div>
            <div class="col-5">
              <select
                v-model="filter.village"
                v-on:change="fetchData()"
                class="form-control mb-2"
              >
                <option value="">Pilih Kelurahan</option>
                <option
                  v-for="villages in villageList"
                  :value="villages.id_kel"
                  :key="villages.id_kel"
                >
                  {{ villages.nama_kelurahan }}
                </option>
              </select>
            </div>
            <div class="col-2 mb-2">
              <button
                type="button"
                v-on:click="downloadExcel()"
                to="/data-transaksi/create"
                class="btn btn-success btn-block"
              >
                <i class="fa fa-file-excel d-md-none"></i>
                <span class="d-none d-md-block">Export Excel</span>
              </button>
            </div>
          </div>
          <vue-good-table
            mode="remote"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            @on-search="onSearch"
            :totalRows="totalRecords"
            :isLoading.sync="isLoading"
            :search-options="{
              enabled: true,
              trigger: 'keyup',
              placeholder: 'Pencarian',
            }"
            :pagination-options="{
              enabled: true,
              perPageDropdownEnabled: true,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              dropdownAllowAll: false,
              position: 'bottom',
              rowsPerPageLabel: '',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: '',
            }"
            :rows="rows"
            :columns="columns"
          >
            <div slot="emptystate" class="text-center">
              {{ tableInfo }}
            </div>
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-default mr-2"
                >
                  <i
                    class="fa fa-sync"
                    :class="{ 'fa-spin': this.isLoading }"
                  ></i>
                </button>
                <router-link
                  to="/data-pelanggan/create"
                  class="btn btn-primary mr-2"
                >
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Pelanggan Baru</span>
                </router-link>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'action'">
                <div>
                  <router-link
                    :to="'/data-pelanggan/detail/' + props.row.customer_id"
                    class="btn btn-circle btn-icon btn-success m-l-5 m-r-5"
                  >
                    <i class="fa fa-search"></i>
                  </router-link>
                  <router-link
                    :to="'/data-pelanggan/update/' + props.row.customer_id"
                    class="btn btn-circle btn-icon btn-warning m-r-5"
                  >
                    <i class="fa fa-pencil-alt"></i>
                  </router-link>
                  <button
                    v-if="userLevel == 'admin' || userLevel == 'loket'"
                    class="btn btn-circle btn-icon btn-danger"
                    v-on:click="confirmDelete(props.row.customer_id)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                  <button
                    v-if="
                      ['satgas', 'kelurahan', 'penagih'].includes(userLevel) &&
                      props.row.created_by == userId
                    "
                    class="btn btn-circle btn-icon btn-danger"
                    v-on:click="confirmDelete(props.row.customer_id)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";
import moment from "moment";
const ExcelJS = require("exceljs");

export default {
  mixins: [sipData],
  data() {
    return {
      columns: [
        {
          label: "NPWRD",
          field: "npwrd",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Nama",
          field: "customer_name",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-wrap",
        },
        {
          label: "NIK / NIB / No. Referensi",
          field: "identity_number",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Alamat",
          field: "customer_address",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-wrap",
        },
        {
          label: "Kelurahan",
          field: "village_name",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap text-uppercase",
        },
        {
          label: "Jenis Kegiatan",
          field: "category_name",
          sortable: false,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap text-uppercase",
        },
        {
          label: "Satuan",
          field: "cost",
          sortable: false,
          formatFn: this.currencyFormat,
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Diinput Oleh",
          field: "created_name",
          sortable: false,
          thClass: "text-center text-wrap valign-middle",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Aksi",
          field: "action",
          sortable: false,
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      tableInfo: "Loading data",
      isLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          {
            field: "",
            type: "",
          },
        ],
        page: 1,
        perPage: 10,
      },
      filter: {
        district: "",
        village: "",
      },
      searchTerm: "",
      timeoutSearch: null,
      loadDPT: false,
      districtList: [],
      villageList: [],
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    this.fetchData();
    this.fetchDistricts();
  },
  methods: {
    onSearch(params) {
      clearTimeout(this.timeoutSearch);
      this.timeoutSearch = setTimeout(() => {
        this.searchTerm = params.searchTerm;
        this.fetchData();
      }, 500);
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.fetchData();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.fetchData();
    },

    onSortChange(params) {
      this.updateParams({
        sort: [
          {
            type: params.sortType,
            field: this.columns[params.columnIndex].field,
          },
        ],
      });
      this.fetchData();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.fetchData();
    },

    fetchData() {
      this.isLoading = true;
      axios
        .get("/v1/customers/", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            district: this.filter.district,
            village: this.filter.village,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.totalRecords = response.data.total;
          if (this.totalRecords <= 0) {
            this.tableInfo = "Empty data";
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/customers/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              title: "Gagal",
              text: error.response.data.message,
              icon: "error",
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.fetchData();
            });
            console.log(error.response.data.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    currencyFormat(value) {
      if (!value) return "";
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    currencyexcelFormat(value) {
      if (!value) return "";
      return "Rp. " + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },

    downloadExcel() {
      axios
        .get("/v1/customers/export", {
          headers: {
            token: this.userToken,
          },
          params: {
            search: this.searchTerm,
            page: this.serverParams.page,
            limit: this.serverParams.perPage,
            district: this.filter.district,
            village: this.filter.village,
          },
        })
        .then((response) => {
          let data = response.data.data;

          let dataArray = data.map((item, index) => ({
            index: index + 1,
            npwrd: parseInt(item.npwrd) || " ",
            customer_name: item.customer_name.toUpperCase() || " ",
            identity_number: item.identity_number.toUpperCase() || " ",
            customer_address: item.customer_address.toUpperCase() || " ",
            district_name: item.district_name.toUpperCase() || " ",
            village_name: item.village_name.toUpperCase() || " ",
            hamlet: item.hamlet.toString() || " ",
            neighbourhood: item.neighbourhood.toString() || " ",
            category_name: item.category_name.toUpperCase() || " ",
            cost: this.currencyexcelFormat(item.cost) || " ",
            created_name: item.created_name.toUpperCase() || " ",
            created_at:
              this.indonesianFormat(item.created_at).toUpperCase() || " ",
          }));

          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet("Data Pelanggan");

          const columnWidths = [
            { header: "NO.", key: "index", width: 5 },
            { header: "NPWRD", key: "npwrd", width: 15 },
            { header: "NAMA", key: "customer_name", width: 30 },
            {
              header: "ALAMAT",
              key: "customer_address",
              width: 40,
            },
            { header: "NOMOR IDENTITAS", key: "identity_number", width: 20 },
            { header: "KECAMATAN", key: "district_name", width: 20 },
            { header: "KELURAHAN", key: "village_name", width: 20 },
            { header: "RW", key: "hamlet", width: 7 },
            { header: "RT", key: "neighbourhood", width: 7 },
            {
              header: "JENIS KEGIATAN",
              key: "category_name",
              width: 35,
            },
            { header: "SATUAN", key: "cost", width: 15 },
            { header: "NAMA PENGINPUT", key: "created_name", width: 18 },
            {
              header: "TANGGAL DIINPUT",
              key: "created_at",
              width: 25,
            },
          ];

          worksheet.columns = columnWidths;

          dataArray.forEach((item) => {
            worksheet.addRow(item);
          });

          const columnCenter = [
            "npwrd",
            "identity_number",
            "category_name",
            "district_name",
            "village_name",
            "hamlet",
            "neighbourhood",
            "created_at",
            "created_name",
          ];
          columnCenter.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
          });

          const columnRight = ["index", "cost"];
          columnRight.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "right",
              vertical: "middle",
              wrapText: true,
            };
          });

          const columnLeft = ["customer_name", "customer_address"];
          columnLeft.forEach((columnName) => {
            const column = worksheet.getColumn(columnName);
            column.alignment = {
              horizontal: "left",
              vertical: "middle",
              wrapText: true,
            };
          });

          const headerRow = worksheet.getRow(1);
          headerRow.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = {
              horizontal: "center",
              vertical: "middle",
              wrapText: true,
            };
          });

          const borderStyle = {
            style: "thin",
            color: { argb: "000000" },
          };

          worksheet.eachRow((row) => {
            row.eachCell((cell) => {
              cell.border = {
                top: borderStyle,
                left: borderStyle,
                bottom: borderStyle,
                right: borderStyle,
              };
            });
          });

          return workbook.xlsx.writeBuffer();
        })
        .then((excelBuffer) => {
          this.saveAsExcelFile(excelBuffer, "data-pelanggan.xlsx");
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    saveAsExcelFile(buffer, filename) {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = filename;
        link.click();
      }
    },
    fetchDistricts() {
      axios
        .get("/v1/daerah/kecamatan/7271", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.districtList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchVillages(event) {
      this.filter.village = "";
      this.fetchData();
      axios
        .get("/v1/daerah/kelurahan/" + event.target.value, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.villageList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    indonesianFormat(dateTime) {
      return moment.unix(dateTime).format("D MMMM YYYY");
    },
  },
};
</script>